import { useState } from 'react';
import { Typography } from 'Components/atoms/Typography';
import Draggable, { DraggableData, DraggableEvent, DraggableEventHandler } from 'react-draggable';
import { RiDraggable } from '@react-icons/all-files/ri/RiDraggable';
import { FiAlertCircle } from '@react-icons/all-files/fi/FiAlertCircle';
import { PROMPT_CONFIGURATIONS, SIDE_PANEL_SUB_TEXT } from 'Core-utils/constants/constants';
import { CanvasType } from 'Core-utils/types';
import classNames from 'classnames';
import './styles.scss';

export type PanelItem = Pick<CanvasType, 'id' | 'name' | 'disabled' | 'isDraggable'>;

interface EngagementStudioSidePanelProps {
    onItemDragStop: (itemId: number, event: DraggableEvent, newPosition: { x: number; y: number }) => void;
    panelItems: PanelItem[];
    onDrag?: DraggableEventHandler;
}

const EngagementStudioSidePanel = ({ onItemDragStop, panelItems, onDrag }: EngagementStudioSidePanelProps) => {
    const [positions, setPositions] = useState<{ [key: number]: { x: number; y: number } }>({});

    const handleDragStop = (id: number) => (event: DraggableEvent, draggable: DraggableData) => {
        setPositions((prevPositions) => ({
            ...prevPositions,
            [id]: { x: 0, y: 0 },
        }));
        event.stopPropagation();
        onItemDragStop(id, event, { x: draggable.x, y: draggable.y });
    };

    return (
        <div className="bg-text-black side-panel ps-4">
            <Typography
                variant="body8"
                color="text-high-emphasis"
                style={{ marginBottom: '0%', paddingTop: '1.5rem', paddingBottom: '0.25rem' }}
            >
                {PROMPT_CONFIGURATIONS}
            </Typography>
            <Typography
                variant="body5"
                style={{ marginBottom: '0%', paddingRight: '1.5rem', paddingBottom: '2rem' }}
                color="text-medium-emphasis"
            >
                {SIDE_PANEL_SUB_TEXT}
            </Typography>
            {panelItems.map((item) => {
                const disabled = item.name === 'Rewards' ? false : item.disabled;
                const className = classNames('side-panel__draggable d-flex align-items-center gap-1', {
                    'bg-text-low-emphasis pe-none': disabled,
                    'bg-text-high-emphasis': !disabled,
                });
                const typographyColor = item.isDraggable ? 'text-black' : 'structural-stroke-100';
                return (
                    <Draggable
                        onDrag={onDrag}
                        key={item.id}
                        onStop={handleDragStop(item.id)}
                        position={positions[item.id]}
                        disabled={disabled}
                        data-testid="draggable-icon"
                    >
                        <div className={className}>
                            {item.isDraggable ? (
                                <RiDraggable className={disabled ? 'invisible' : 'visible'} />
                            ) : (
                                <FiAlertCircle className="text-secondary-accent-red-2" size={16} />
                            )}
                            <Typography variant="body8" color={typographyColor} style={{ marginBottom: '0%' }}>
                                {item.name}
                            </Typography>
                        </div>
                    </Draggable>
                );
            })}
        </div>
    );
};

export default EngagementStudioSidePanel;
