import { useMemo, useState } from 'react';
import { CgArrowsExpandRight } from '@react-icons/all-files/cg/CgArrowsExpandRight';
import { REPORTS } from '@ampsec/platform-client';
import { CustomCard } from 'Components/molecules';
import { HealthMetrics } from 'Components/molecules';
import { IconWithTypography } from 'Components/molecules';
import {
    ToolingCoverageCtxLabelMap,
    TOOLING_COVERAGE_GAPS,
    TOTAL_EMPLOYEE_LABEL,
    TOTAL_ENDPOINT_LABEL,
    INACTIVE,
} from 'Core-utils/constants/constants';
import { getMetricsReportsData } from 'Apis/library';
import { Typography } from 'Components/atoms';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import { DescriptiveMetric, DescriptiveMetricResults } from 'Core-utils/types/types';
import { employeeToolingTransformerFunction, metricLabelColor } from 'Core-utils/helpers/ReportsHelper';
import toolingIcon from 'Assets/icons/toolingCoverage.svg';
import _ from 'lodash';
import { UsersInsightsModal } from 'Components/organisms';
import { useI18n } from 'Hooks/useI18n';
import './styles.scss';
import { MetricProps } from 'Components/molecules/HealthMetrics/HealthMetrics';

const COMPONENT_IDENTIFIER = 'Organization Tooling Coverage Gaps';

interface ToolingCoverageMetricsProps {
    employeeToolingData: Record<string, DescriptiveMetric>;
    ToolingCoverageData: DescriptiveMetricResults;
}

const buildHealthMetric = (healthMetric: DescriptiveMetric, header: string) => {
    const metricValue = healthMetric?.value;
    const label = metricValue === null ? '-' : metricValue?.toString();
    const labelColor = metricLabelColor(metricValue);
    const isTotal = healthMetric.label === TOTAL_EMPLOYEE_LABEL || healthMetric.label === TOTAL_ENDPOINT_LABEL;
    const value = (
        <div className="row-container-style d-flex flex-col">
            <div>
                <Typography variant="caption2" color="text-high-emphasis">
                    {ToolingCoverageCtxLabelMap[healthMetric.ctxLabel]}
                </Typography>
            </div>
            {isTotal ? (
                <div className="tooling-coverage__styled-space">
                    <Typography variant="caption2" children={healthMetric.ctxValue?.toString()} color="success-green" />
                </div>
            ) : null}
        </div>
    );

    const valueVariant: 'caption2' | 'body2' = 'caption2';

    return { header, label, labelColor, value, valueVariant, isTotal, ctxValue: healthMetric.ctxValue };
};

const renderHealthMetrics = (
    metrics: MetricProps[],
    totalEndpoints: number,
    totalEDR: number,
    totalEDRMock: number,
    EDRLabels: string[],
    EDRMockLabels: string[],
) => {
    const modifiedMetrics = metrics.map((metric) => {
        let modifiedMetric;
        if (metric.header && EDRLabels.includes(metric?.header?.toString())) {
            if (totalEDR >= 0 && totalEDR <= totalEndpoints) {
                modifiedMetric = { ...metric, label: (totalEndpoints - totalEDR).toString() };
            } else {
                if (totalEDR < 0) {
                    modifiedMetric = { ...metric, label: '0' };
                } else if (totalEDR > totalEndpoints) {
                    modifiedMetric = { ...metric, label: totalEndpoints.toString() };
                }
            }
        } else if (metric.header && EDRMockLabels.includes(metric?.header?.toString())) {
            if (totalEDRMock > 0 && totalEDRMock <= totalEndpoints) {
                modifiedMetric = { ...metric, label: (totalEndpoints - totalEDRMock).toString() };
            } else {
                if (totalEDRMock < 0) {
                    modifiedMetric = { ...metric, label: '0' };
                } else if (totalEDRMock > totalEndpoints) {
                    modifiedMetric = { ...metric, label: totalEndpoints.toString() };
                }
            }
        } else {
            modifiedMetric = { ...metric };
        }
        return modifiedMetric;
    });

    modifiedMetrics.sort((a, b) => {
        const labelA = parseInt(a?.label || '0');
        const labelB = parseInt(b?.label || '0');
        return labelB - labelA;
    });

    const topFourMetrics = modifiedMetrics.slice(0, 4);

    return topFourMetrics.map((metric) => (
        <div className="tooling-coverage__endpoint-tooling-coverage" key={metric?.label} data-testid="health-metric">
            <HealthMetrics
                metric={metric ?? ({} as MetricProps)}
                hideValue={true}
                lowerLabel={INACTIVE}
                headerClassName="tooling-coverage__header"
            />
        </div>
    ));
};

const calculateTotalValue = (
    employeeToolingData: Record<string, DescriptiveMetric>,
    ToolingCoverageData: DescriptiveMetricResults,
    labelList: string[],
) => {
    const { t } = useI18n();
    return Object.keys(ToolingCoverageData)
        .slice(Object.keys(employeeToolingData).length + 1)
        .map((key) => ToolingCoverageData[key] || { label: '', value: 0 })
        .filter((healthMetric) => labelList.includes(t(healthMetric.label)))
        .reduce((acc, healthMetric) => acc + healthMetric.ctxValue, 0);
};

const ToolingCoverageMetrics = ({ employeeToolingData, ToolingCoverageData }: ToolingCoverageMetricsProps) => {
    const { t } = useI18n();
    const EDR_LABELS = [t('provider_intune_name'), t('provider_kandji_name'), t('provider_jamf_name')];
    const EDR_MOCK_LABELS = [
        t('provider_intune_mock_name'),
        t('provider_kandji_mock_name'),
        t('provider_jamf_mock_name'),
    ];
    const beforeVrContent = Object.keys(ToolingCoverageData)
        .slice(0, Object.keys(employeeToolingData).length + 1)
        .map((key) => {
            const healthMetric = ToolingCoverageData[key] ?? {
                label: '',
                value: 0,
            };
            const metric = buildHealthMetric(healthMetric, t(healthMetric.label));
            return metric;
        });

    const afterVrContent = Object.keys(ToolingCoverageData)
        .slice(Object.keys(employeeToolingData).length + 1)
        .map((key) => {
            const healthMetric = ToolingCoverageData[key] ?? {
                label: '',
                value: 0,
            };
            const metric = buildHealthMetric(healthMetric, t(healthMetric.label));
            return metric;
        });
    const totalEDR = calculateTotalValue(employeeToolingData, ToolingCoverageData, EDR_LABELS);
    const totalEDRMock = calculateTotalValue(employeeToolingData, ToolingCoverageData, EDR_MOCK_LABELS);
    const totalEndpoints =
        Object.keys(ToolingCoverageData)
            .slice(0, Object.keys(employeeToolingData).length + 1)
            .map((key) => ToolingCoverageData[key] || { label: '', value: 0 })
            .find((healthMetric) => t(healthMetric.label) === 'ENDPOINTS')?.value || 0;

    return (
        <div className="d-flex gap-2" data-testid="tooling-coverage-content">
            <div className="tooling-coverage__styled-container p-1">
                {beforeVrContent.map((metric) => (
                    <div className="tooling-coverage__endpoint-tooling-coverage" key={metric.label}>
                        <HealthMetrics
                            metric={metric}
                            hideValue={true}
                            lowerLabel={t('ACTIVE')}
                            headerClassName="tooling-coverage__header"
                        />
                    </div>
                ))}
            </div>
            {employeeToolingData && <div className="vr" />}
            <div className="tooling-coverage__styled-child align-items-end">
                {renderHealthMetrics(
                    afterVrContent,
                    totalEndpoints,
                    totalEDR,
                    totalEDRMock,
                    EDR_LABELS,
                    EDR_MOCK_LABELS,
                )}
            </div>
        </div>
    );
};

const ToolingCoverage = () => {
    const [show, setShow] = useState(false);

    const {
        data: employeeToolingData,
        error: employeeToolingDataError,
        isLoading: employeeToolingDataisLoading,
        refresh,
    } = useApiObjectData(REPORTS.EMPLOYEE_TOOLING_COVERAGE, getMetricsReportsData, employeeToolingTransformerFunction, {
        department: 'ALL',
    });

    const handleClose = () => {
        setShow(false);
    };
    const handleClick = () => {
        setShow(!show);
    };
    const {
        data: endpointToolingData,
        error: endpointToolingDataError,
        isLoading: endpointToolingDataisLoading,
    } = useApiObjectData<DescriptiveMetricResults, DescriptiveMetricResults>(
        REPORTS.ENDPOINT_TOOLING_COVERAGE,
        getMetricsReportsData,
        (a) => a,
        {
            department: 'ALL',
        },
    );

    const toolingCoverageData = useMemo(
        () => ({
            ...employeeToolingData,
            ...endpointToolingData,
        }),
        [employeeToolingData, endpointToolingData],
    );

    return (
        <div className="tooling-coverage h-100" data-testid="tooling-coverage">
            <CustomCard
                cardType="twoThird"
                title={
                    <>
                        <IconWithTypography isRow={true} label={TOOLING_COVERAGE_GAPS} labelColor="primary" />
                        <CgArrowsExpandRight
                            size={22}
                            className="tooling-coverage__icon"
                            onClick={handleClick}
                            data-testid="expand-icon"
                        />
                    </>
                }
                content={
                    <div className="tooling-coverage__error-container">
                        <ErrorHandlerAndLoaderWrapper
                            error={employeeToolingDataError || endpointToolingDataError}
                            isLoading={employeeToolingDataisLoading || endpointToolingDataisLoading}
                            render={() => (
                                <ToolingCoverageMetrics
                                    ToolingCoverageData={toolingCoverageData}
                                    employeeToolingData={employeeToolingData}
                                />
                            )}
                            handleRefresh={refresh}
                            isEmpty={_.isEmpty(employeeToolingData) && _.isEmpty(endpointToolingData)}
                            ImageSrc={toolingIcon}
                            component={COMPONENT_IDENTIFIER}
                        />
                    </div>
                }
            />
            {show && <UsersInsightsModal show={show} onClose={handleClose} title={TOOLING_COVERAGE_GAPS} />}
        </div>
    );
};

export default ToolingCoverage;
