import { useEffect, useState } from 'react';
import { BsFilter } from '@react-icons/all-files/bs/BsFilter';
import { Dropdown } from 'bootstrap';
import { FilterActionButtons } from 'Components/molecules';
import { CheckboxList } from 'Components/molecules';
import { Typography } from 'Components/atoms';
import { FindingsGridHeaderFilterProps, SortType } from 'Core-utils/types/types';
import { SortDropdown } from 'Components/molecules';
import './styles.scss';

const FindingsGridHeaderFilter = ({
    columnName,
    sortable,
    filterable,
    position,
    handleTableFilters,
    text,
    filterOptions,
    selectedFilterOptions,
    selectedSortDirection,
    sortLowLabel,
    sortHighLabel,
}: FindingsGridHeaderFilterProps) => {
    const [sortDirection, setSortDirection] = useState<SortType>(null);
    const [filters, setFilters] = useState<string[]>([]);
    const [disableClear, setDisableClear] = useState<boolean>(true);

    const buttonTextVariant =
        (sortable && selectedSortDirection) || (filterable && selectedFilterOptions.length) ? 'body1' : 'body5';

    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id: option, checked: isChecked } = event.target;
        setFilters((prevFilters: string[]) => {
            if (isChecked) {
                return [...prevFilters, option];
            }
            return prevFilters.filter((selectedOption) => selectedOption !== option);
        });
    };

    const closeDropdown = () => {
        const columnNameInId = columnName.replace('.', '-');
        const dropdown = Dropdown.getOrCreateInstance(`#grid-head-filter-dropdown-${columnNameInId}`);
        dropdown.hide();
        dropdown.dispose();
        const dropdownToggle = document.getElementById(`grid-head-filter-dropdown-toggle-${columnNameInId}`);
        dropdownToggle && dropdownToggle.classList.remove('show');
    };

    const handleSort = (currentSortDirection: SortType) => setSortDirection(currentSortDirection);

    const handleClearClick = () => {
        setFilters([]);
        setSortDirection(null);
    };

    const handleApplyClick = () => {
        handleTableFilters({
            sortColumn: sortable ? columnName : null,
            filteredColumn: filterable ? columnName : null,
            filters,
            sortDirection,
        });
        closeDropdown();
    };

    const handleToggle = () => {
        sortable && setSortDirection(selectedSortDirection);
        filterable && setFilters(selectedFilterOptions);
    };

    useEffect(() => {
        setDisableClear(filters.length === 0 && !sortDirection);
    }, [sortDirection, filters]);

    return (
        <div className={`grid-head-filter text-center d-flex justify-content-${position} w-100`}>
            <div
                className="dropdown gap-3 data-grid-dropdown-container"
                id={`grid-head-filter-dropdown-${columnName.replace('.', '-')}`}
            >
                <button
                    id={`grid-head-filter-dropdown-toggle-${columnName.replace('.', '-')}`}
                    className="btn dropdown-toggle no-after-content border-0 p-0"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    onClick={handleToggle}
                >
                    <Typography variant={buttonTextVariant}>
                        {text} <BsFilter />
                    </Typography>
                </button>

                <ul
                    className="dropdown-menu grid-head-filter-dropdown-menu data-grid-dropdown dropdown-menu-dark bg-structural-CardBG text-text-high-emphasis p-0"
                    id={`grid-head-filter-dropdown-menu-${columnName.replace('.', '-')}`}
                >
                    {sortable && (
                        <SortDropdown
                            sortDirection={sortDirection}
                            handleSort={handleSort}
                            sortLowLabel={sortLowLabel}
                            sortHighLabel={sortHighLabel}
                        />
                    )}
                    {sortable && filterable && <hr className="dropdown-divider bg-structural-badge-bg m-0" />}
                    {filterable === true && filterOptions && (
                        <CheckboxList
                            options={filterOptions}
                            handleCheckbox={handleCheckbox}
                            selectedOptions={filters}
                        />
                    )}
                    {(sortable || filterable) && <hr className="dropdown-divider bg-structural-badge-bg m-0" />}
                    <FilterActionButtons
                        onClearClick={handleClearClick}
                        onApplyClick={handleApplyClick}
                        applyDisabled={false}
                        clearDisabled={disableClear}
                    />
                </ul>
            </div>
        </div>
    );
};

export default FindingsGridHeaderFilter;
