import { useContext, useEffect, useMemo, useState } from 'react';
import {
    Page,
    ProviderDto,
    FindingStatus,
    Category,
    FilterCriteria,
    FindingsInsightsDto,
    ConnectorDto,
    SortOptions,
    StringMatcher,
    AuthRole,
    FlowSpecDto,
    FlowSpecStatusKind,
    FindingKind,
} from '@ampsec/platform-client';
import { Table } from 'Components/organisms';
import { GridStateType, HandleTableFiltersProps, SortType } from 'Core-utils/types/types';
import {
    SEVERITY_TEXT,
    STATUS,
    DEPARTMENT,
    PROVIDER,
    NUMBER_OF_ENGAGEMENTS,
    FINDING,
    NAME,
    DESCRIPTION,
    FindingStatusLabelMap,
    ISSUE_TYPE_TEXT,
    DATE_TEXT,
    FINDINGS_GRID_DEFAULT_HEIGHT,
    securityFindingsColumnMap,
} from 'Core-utils/constants/constants';
import { getFindingsView, getGridData } from 'Apis/library';
import _ from 'lodash';
import {
    DateTimeFormatter,
    extractQueryParameters,
    opacityViewClassName,
    updateURLWithFiltersAndSorting,
} from 'Core-utils/helpers/helpers';
import { Typography } from 'Components/atoms';
import { Link } from 'react-router-dom';
import { USERS_DASHBOARD_ROUTE } from 'Core-utils/routes';
import { ContentfulContext } from 'Rdx/contexts/contentfulContext';
import { FindingChip, FindingsGridHeaderFilter } from 'Components/molecules';
import classNames from 'classnames';
import { Chip } from 'Components/atoms';
import { TableColumn } from 'react-data-table-component';
import { getApi } from 'Src/Api';
import { useSelector } from 'react-redux';
import { RootState } from 'Rdx/store';
import { CustomTooltip } from 'Components/atoms';
import PageErrorHandlerAndLoaderWrapper from 'Wrappers/PageErrorHandlerAndLoaderWrapper';
import { useI18n } from 'Hooks/useI18n';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import './styles.scss';
import ActionsTray from '../ActionsTray/ActionsTray';

interface FindingsGridProps {
    defaultFilter?: { [key: string]: string[] };
    enableUpdatingURLWithFiltersAndSorting?: boolean;
    tableBodyHeight?: string;
    showSubHeader: boolean;
    filter?: FilterCriteria;
    data?: any;
    dataIsLoading?: boolean;
    reset?: boolean;
    error?: string;
}

const FindingsGrid = ({
    enableUpdatingURLWithFiltersAndSorting,
    tableBodyHeight = FINDINGS_GRID_DEFAULT_HEIGHT,
    showSubHeader,
    data: queryData,
    dataIsLoading,
    filter,
    reset,
    defaultFilter = {},
    error: queryError,
}: FindingsGridProps) => {
    const { t } = useI18n();
    const FINDINGS_PAGE_ROWS_PER_PAGE_OPTIONS = t('__FINDINGS_PAGE_ROWS_PER_PAGE_OPTIONS').split(',').map(Number);
    const [limit, setLimit] = useState(50);
    const auth = useSelector((state: RootState) => state.auth);
    const [actionsShow, setActionsShow] = useState<boolean>(true);
    const [departments, setDepartments] = useState<string[]>([]);
    const [clear, setClear] = useState<boolean>(false);
    const transformer = (dataItem: Page<FindingsInsightsDto>) => ({ ...dataItem });
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<FindingsInsightsDto[]>([]);
    const [error, setError] = useState<string | undefined>(queryError);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Page<any> | undefined>(queryData);
    const [page, setPage] = useState(1);
    const { contentfulData } = useContext(ContentfulContext);
    const integrations = contentfulData?.integrations;
    const [providerFilterOptions, setProviderFilterOptions] = useState<string[]>([]);
    const [providerFilterOptionToProviderKeyMap, setProviderFilterOptionsToProviderKeyMap] = useState<{
        [key: string]: ProviderDto;
    }>();
    const initialQueryParameters = extractQueryParameters();
    const organization = useSelector((state: RootState) => state.orgModel.org);
    const userOrganization = securityFindingsColumnMap.userOrganization;
    const filteredOrganization = organization === 'ALL' ? [] : [organization];
    const defaultCategoryFilter = {
        [userOrganization]: filteredOrganization,
        ...defaultFilter,
        ...initialQueryParameters,
    };
    const { pidProviderMap, installedConnectorMap } = useSelector((state: RootState) => state);
    const contentfulProviderMap = contentfulData?.providerNameIntegrationMap;
    const [appliedFilters, setAppliedFilters] = useState<GridStateType>({
        sortColumn: initialQueryParameters?.sortColumn?.[0],
        sortDirection: initialQueryParameters?.sortDirection?.[0] as SortType,
        filters: defaultCategoryFilter,
    });

    const flowSpecTransformer = (dataItem: Page<FlowSpecDto>) => {
        return dataItem.data;
    };

    const { data: fetchFlowSpecs } = useApiObjectData('flowSpecs', getGridData, flowSpecTransformer, {
        limit: 100,
    });

    const matchingFlowSpecs = useMemo(() => {
        if (selectedRows.length > 0) {
            const eventTypes = selectedRows.map((row) => row.kind);
            return fetchFlowSpecs
                .filter((fs) => fs.status !== FlowSpecStatusKind.PAUSED)
                .filter((fs) =>
                    // eslint-disable-next-line max-nested-callbacks
                    fs.triggerFilter.trigger.some((t) => eventTypes.includes(t.eventType as FindingKind)),
                );
        }
        return [];
    }, [selectedRows]);

    const [filters, sort] = useMemo(() => {
        const tempFilter: { [key in string]: StringMatcher } = {};
        for (const key in appliedFilters.filters) {
            tempFilter[key] = { $in: appliedFilters.filters[key] };
        }

        if (appliedFilters.sortColumn && appliedFilters.sortDirection) {
            const tempSort: SortOptions = { [appliedFilters['sortColumn']]: appliedFilters.sortDirection };
            return [tempFilter, tempSort];
        }
        return [tempFilter, {}];
    }, [appliedFilters]);

    useEffect(() => {
        const getDepartments = async () => {
            const api = await getApi();
            const departmentsDtos = await api?.enums.departments.list({});
            const departments: string[] =
                departmentsDtos?.data.reduce((prev: string[], cur) => prev.concat(Object.keys(cur)), []).sort() ?? [];
            setDepartments(departments);
        };

        getDepartments();
    }, []);

    useEffect(() => {
        if (!pidProviderMap || !contentfulProviderMap) {
            return;
        }
        const providerFilterOptions: string[] = [];
        const providerFilterOptionToProviderKeyMap: { [key: string]: ProviderDto } = {};

        Object.values(installedConnectorMap)?.forEach((connector: ConnectorDto) => {
            if (!connector?.pid) {
                return;
            }
            const providerDisplayValue = pidProviderMap?.[connector?.pid]?.displayValue;
            const uiDisplayValue = contentfulProviderMap?.[providerDisplayValue]?.name;
            providerFilterOptions.push(uiDisplayValue);
            providerFilterOptionToProviderKeyMap[uiDisplayValue] = pidProviderMap?.[connector?.pid];
        });

        setProviderFilterOptions(providerFilterOptions);
        setProviderFilterOptionsToProviderKeyMap(providerFilterOptionToProviderKeyMap);
    }, [pidProviderMap, contentfulProviderMap, installedConnectorMap]);

    useEffect(() => {
        setLoading(true);
        getFindingsView(filter ? filter : {}, { ...filters, sort, limit, offset: (page - 1) * limit })
            .then((res: any) => {
                setData(res);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [filters, sort, limit, page, filter, refreshData]);

    useEffect(() => {
        if (reset) {
            setAppliedFilters((prevFilters) => ({
                ...prevFilters,
                filters: {},
            }));
        }
    }, [reset]);

    const columnFilterMap: Record<string, string> = {
        provider: 'provider_name',
    };

    const handleSelectedRowsChange = (selected: {
        allSelected: boolean;
        selectedCount: number;
        selectedRows: FindingsInsightsDto[];
    }) => {
        setSelectedRows(selected.selectedRows);
        if (selected.selectedRows.length > 0) {
            setActionsShow(true);
        }
    };

    const handleActionsClose = () => {
        setSelectedRows([]);
        setClear((clear) => !clear);
        setActionsShow(false);
    };

    const clearSelectedRows = () => {
        setSelectedRows([]);
    };

    const handleTableFilters = ({ sortColumn, sortDirection, filteredColumn, filters }: HandleTableFiltersProps) => {
        const mappedColumn = columnFilterMap?.[filteredColumn ?? ''] || filteredColumn;
        const mappedSortColumn = columnFilterMap?.[sortColumn ?? ''] || sortColumn;
        setAppliedFilters((prevFilters) => {
            const tempState = {
                ...prevFilters,
                filters: {
                    ...prevFilters.filters,
                    ...(mappedColumn
                        ? {
                              [mappedColumn]:
                                  mappedColumn === 'provider_name' // HACK we need a more flexible solution for when column name isn't API field
                                      ? (filters.map(
                                            (filter) => providerFilterOptionToProviderKeyMap?.[filter]?.displayValue,
                                        ) as string[])
                                      : filters,
                          }
                        : {}),
                },
            };
            if (mappedSortColumn === tempState.sortColumn) {
                tempState.sortDirection = sortDirection;
            } else if (mappedSortColumn && sortDirection !== null) {
                tempState.sortColumn = mappedSortColumn;
                tempState.sortDirection = sortDirection;
            }
            return tempState;
        });
    };

    const changePageHandler = (currentPage: number) => {
        setPage(currentPage);
    };
    const changeRowsPerPageHandler = (rowsOption: number) => {
        setLimit(rowsOption);
    };

    const providerDisplayValueToLogoMap = integrations
        ? Object.keys(integrations)?.reduce((acc: { [k: string]: string }, integration: string) => {
              acc[
                  integrations?.[integration].providerKey
              ] = `https:${integrations?.[integration]?.logo?.fields?.file?.url}`;
              return acc;
          }, {})
        : {};

    const columns: TableColumn<FindingsInsightsDto>[] = [
        {
            id: 'severity',
            name: (
                <div className="w-100 text-center">
                    <Typography variant="body2">{SEVERITY_TEXT}</Typography>
                </div>
            ),
            center: true,
            sortable: true,
            width: '9.375rem',
            wrap: true,
            cell: (row) => {
                const variant = row.severity ? 'resolved_issue_variant' : 'severity';
                return (
                    <div className={`text-uppercase ${opacityViewClassName(row)}`}>
                        <Chip value={row.severity} variant={variant} />
                    </div>
                );
            },
        },
        {
            id: 'finding_status',
            name: (
                <FindingsGridHeaderFilter
                    text={STATUS}
                    sortable={true}
                    filterable={true}
                    filterOptions={Object.values(FindingStatus)}
                    selectedFilterOptions={appliedFilters?.filters?.['finding_status'] ?? []}
                    selectedSortDirection={
                        appliedFilters.sortColumn === 'finding_status' ? appliedFilters.sortDirection : null
                    }
                    columnName="finding_status"
                    handleTableFilters={handleTableFilters}
                />
            ),
            wrap: true,
            cell: (row) => {
                return (
                    <div className={`text-uppercase ${opacityViewClassName(row)}`}>
                        <Typography variant={'subtitle3'}>
                            {FindingStatusLabelMap?.[row.status] || row.status}
                        </Typography>
                    </div>
                );
            },
            width: '7.5rem',
        },
        {
            id: 'finding_category',
            name: (
                <FindingsGridHeaderFilter
                    text={ISSUE_TYPE_TEXT}
                    sortable={true}
                    filterable={true}
                    filterOptions={Object.values(Category)}
                    selectedFilterOptions={appliedFilters?.filters?.['finding_category'] ?? []}
                    selectedSortDirection={
                        appliedFilters.sortColumn === 'finding_category' ? appliedFilters.sortDirection : null
                    }
                    columnName="finding_category"
                    handleTableFilters={handleTableFilters}
                />
            ),
            cell: (row) => (
                <div className={opacityViewClassName(row)}>
                    <FindingChip
                        provider={row.provider_name || ''}
                        category={row.category}
                        className={opacityViewClassName(row)}
                    />
                </div>
            ),
            width: '11.25rem',
            minWidth: '11.25rem',
        },
        {
            id: 'provider_name',
            name: (
                <FindingsGridHeaderFilter
                    text={PROVIDER}
                    sortable={false}
                    filterable={true}
                    filterOptions={providerFilterOptions}
                    selectedFilterOptions={
                        appliedFilters?.filters?.['provider_name']
                            ? appliedFilters?.filters?.['provider_name'].map((provider) => {
                                  return contentfulProviderMap?.[provider]?.name ?? provider;
                              })
                            : []
                    }
                    columnName="provider_name"
                    handleTableFilters={handleTableFilters}
                />
            ),
            sortable: false,
            width: '10rem',
            cell: (row) => {
                return (
                    <div className={`d-flex flex-row gap-1 ${opacityViewClassName(row)}`}>
                        <img
                            alt={`${row.provider_name} logo`}
                            className="provider_logo"
                            src={
                                (providerDisplayValueToLogoMap?.[row.provider_name!] as string) ||
                                'https://via.placeholder.com/128'
                            }
                        />
                        <div className="d-flex flex-column justify-content-center">
                            <div className={opacityViewClassName(row)}>
                                {contentfulProviderMap?.[row?.provider_name || 'UNKNOWN']?.name ??
                                    `${row.provider_name}*` ??
                                    'UNKNOWN'}
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            id: 'finding_title',
            minWidth: '18.5rem',
            name: <Typography variant="body2">{FINDING}</Typography>,
            width: '18.5rem',
            cell: (row) => (
                <div className={opacityViewClassName(row)}>
                    <Typography variant="subtitle3" style={{ whiteSpace: 'unset' }}>
                        {_.startCase(t(row.title + '_description', row.title ?? 'UNKNOWN'))}
                    </Typography>
                </div>
            ),
        },
        {
            id: 'user_name',
            name: (
                <FindingsGridHeaderFilter
                    text={NAME}
                    sortable={true}
                    filterable={false}
                    selectedSortDirection={
                        appliedFilters.sortColumn === 'user_name' ? appliedFilters.sortDirection : null
                    }
                    columnName="user_name"
                    handleTableFilters={handleTableFilters}
                />
            ),
            cell: (row) => (
                <Link
                    className={`${opacityViewClassName(row)} text-decoration-none data-grid__link`}
                    to={`${USERS_DASHBOARD_ROUTE}/${row.user_id}`}
                >
                    <Typography variant="subtitle3">{row.user_name}</Typography>
                </Link>
            ),
            width: '12.875rem',
        },
        {
            id: 'user_department',
            name: (
                <FindingsGridHeaderFilter
                    text={DEPARTMENT}
                    sortable={true}
                    filterable={true}
                    filterOptions={departments}
                    selectedFilterOptions={appliedFilters?.filters?.['user_department'] ?? []}
                    selectedSortDirection={
                        appliedFilters.sortColumn === 'user_department' ? appliedFilters.sortDirection : null
                    }
                    columnName="user_department"
                    handleTableFilters={handleTableFilters}
                />
            ),
            cell: (row) => (
                <CustomTooltip
                    id={`device-${row.id}`}
                    label={`${row.user_department ?? ''}`}
                    labelColor="primary"
                    labelVariant="subtitle3"
                    overlayPlacement="left"
                    overlayContainerClassName="d-flex flex-column justify-content-center overflow-hidden w-100"
                    tooltipClassName="color-white text-white"
                >
                    <div className={opacityViewClassName(row)}>
                        <Typography variant="subtitle3" className="text-truncate department-row">
                            {row.user_department ?? ''}
                        </Typography>
                    </div>
                </CustomTooltip>
            ),
            width: '11.25rem',
        },
        {
            id: 'device_display_value',
            name: <Typography variant="body2">Device</Typography>,
            cell: (row) => (
                <CustomTooltip
                    id={`device-${row.id}`}
                    label={`${row.device_display_value ?? ''}`}
                    labelColor="primary"
                    labelVariant="subtitle3"
                    overlayPlacement="left"
                    overlayContainerClassName="d-flex flex-column justify-content-center overflow-hidden w-100"
                    tooltipClassName="color-white text-white"
                >
                    <Typography variant="subtitle3">{row.device_display_value ?? ''}</Typography>
                </CustomTooltip>
            ),
            maxWidth: '12rem',
        },
        {
            id: 'discoveredAt',
            name: (
                <FindingsGridHeaderFilter
                    text={DATE_TEXT}
                    sortable={true}
                    filterable={false}
                    selectedSortDirection={
                        appliedFilters.sortColumn === 'discoveredAt' ? appliedFilters.sortDirection : null
                    }
                    columnName="discoveredAt"
                    handleTableFilters={handleTableFilters}
                />
            ),
            cell: (row) => (
                <div className={opacityViewClassName(row)}>
                    <Typography variant="subtitle3">{DateTimeFormatter(row.discoveredAt ?? '')}</Typography>
                </div>
            ),
            width: '12.875rem',
        },
        {
            id: 'description',
            name: <Typography variant="body2">{DESCRIPTION}</Typography>,
            cell: (row) => (
                <CustomTooltip
                    id={`description-${row.id}-${row.user_id}`}
                    label={`${row.description ?? ''}`}
                    labelColor="primary"
                    labelVariant="subtitle3"
                    overlayPlacement="left"
                    overlayContainerClassName="d-flex  overflow-hidden w-100"
                    tooltipClassName="color-white text-white"
                >
                    <div className={`${opacityViewClassName(row)} d-flex overflow-hidden text-overflow-elipsis`}>
                        <Typography variant="subtitle3">{row.description}</Typography>
                    </div>
                </CustomTooltip>
            ),
            maxWidth: '24rem',
            center: false,
        },
        {
            id: 'number_of_engagements',
            name: (
                <FindingsGridHeaderFilter
                    text={NUMBER_OF_ENGAGEMENTS}
                    sortable={true}
                    filterable={false}
                    selectedSortDirection={
                        appliedFilters.sortColumn === 'number_of_engagements' ? appliedFilters.sortDirection : null
                    }
                    columnName="number_of_engagements"
                    handleTableFilters={handleTableFilters}
                    position="center"
                />
            ),
            center: true,
            cell: (row) => (
                <div className={opacityViewClassName(row)}>
                    <Typography variant="subtitle3">{`${row.number_of_engagements || 0}`}</Typography>
                </div>
            ),
            maxWidth: '100%',
            style: {
                maxWidth: '13.875rem',
            },
        },
    ];

    useEffect(() => {
        if (enableUpdatingURLWithFiltersAndSorting) {
            updateURLWithFiltersAndSorting(
                appliedFilters.filters,
                appliedFilters.sortColumn,
                appliedFilters.sortDirection,
            );
        }
    }, [
        enableUpdatingURLWithFiltersAndSorting,
        appliedFilters.filters,
        appliedFilters.sortColumn,
        appliedFilters.sortDirection,
    ]);

    const registerDropdownEventListeners = () => {
        const columnIds = columns.map((column) =>
            column.id && typeof column.id === 'string' ? column.id.replace('.', '-') : '',
        );

        columnIds.forEach((id) => {
            const dropdownElement = document.querySelector(`#grid-head-filter-dropdown-${id}`);
            dropdownElement?.addEventListener('show.bs.dropdown', (_event) => {
                const tableElement = document.querySelector('.rdt_TableBody');
                tableElement?.classList.add('opacity-50', 'pe-none');
            });
            dropdownElement?.addEventListener('hide.bs.dropdown', (_event) => {
                const tableElement = document.querySelector('.rdt_TableBody');
                tableElement?.classList.remove('opacity-50', 'pe-none');
            });
        });

        return columnIds;
    };

    const unRegisterDropdownEventListeners = (columnIds: string[]) => () => {
        columnIds.forEach((id) => {
            const dropdownElement = document.querySelector(`#grid-head-filter-dropdown-${id}`);
            dropdownElement?.removeEventListener('show.bs.dropdown', () => {});
            dropdownElement?.removeEventListener('hide.bs.dropdown', () => {});
        });
    };

    useEffect(() => {
        const columnIds = registerDropdownEventListeners();

        return unRegisterDropdownEventListeners(columnIds);
    }, []);

    const className = classNames(
        'd-flex align-items-center data-grid justify-content-center w-100 h-100 overflow-hidden position-relative',
        {
            'pb-4': enableUpdatingURLWithFiltersAndSorting === true,
        },
    );

    const handleClear = () => {
        setClear((clear) => !clear);
    };

    const handleActionShow = () => {
        setActionsShow(false);
    };

    const handleRefreshData = () => {
        setRefreshData((refreshData) => !refreshData);
    };

    const renderFindingsGrid = () => (
        <Table
            data={data?.data ?? []}
            columns={columns}
            rowsPerPageOptions={FINDINGS_PAGE_ROWS_PER_PAGE_OPTIONS}
            selectableRows={auth.user.role === AuthRole.ADMIN || auth.user.role === AuthRole.OWNER}
            showSubHeader={showSubHeader}
            transformer={transformer}
            cacheKey="findingsInsights"
            subHeaderVariant="with-button"
            onSelectedRowsChange={handleSelectedRowsChange}
            clearSelectedRows={clear}
            refreshTableData={refreshData}
            tableBodyHeight={tableBodyHeight}
            dataIsLoading={dataIsLoading || loading}
            onChangePage={changePageHandler}
            onRowChange={changeRowsPerPageHandler}
            currentPage={page}
            currentLimit={limit}
            paginationTotalRows={data?.hints?.count ?? 0}
            filter={{ ...filters, sort }}
        />
    );
    const noOfUsers = _.uniqBy(selectedRows, 'user_id')?.length;

    return (
        <div className={className}>
            <PageErrorHandlerAndLoaderWrapper error={error} render={renderFindingsGrid} />

            {selectedRows.length > 0 && actionsShow && (
                <ActionsTray
                    showActionsTray={selectedRows.length > 0 && actionsShow}
                    CountOfSelectedItems={selectedRows.length}
                    isFindingsGrid={false}
                    selectedRows={selectedRows}
                    onActionsTrayClose={handleActionsClose}
                    onClear={handleClear}
                    onActionShow={handleActionShow}
                    onRefreshData={handleRefreshData}
                    clearSelectedRows={clearSelectedRows}
                    matchingEngagements={matchingFlowSpecs.map((fs) => fs.name)}
                    noOfUsers={noOfUsers}
                />
            )}
        </div>
    );
};

export default FindingsGrid;
