import { Typography } from 'Components/atoms';
import { UsersInsightsModalBody as ToolingCoverageGrid } from 'Components/organisms/UsersInsightsModal/components';
import { TOOLING_COVERAGE_PAGE } from 'Core-utils/constants/constants';
import './styles.scss';
import useTimeSpent from 'Hooks/useTimeSpent';

const COMPONENT_IDENTIFIER = 'Tooling Coverage Page';

const ToolingCoveragePage = () => {
    useTimeSpent(COMPONENT_IDENTIFIER);
    return (
        <div className="tooling-coverage-page pt-4 pb-4 w-100 h-100 d-flex flex-column gap-2">
            <Typography variant="body11" color="text-high-emphasis">
                {TOOLING_COVERAGE_PAGE}
            </Typography>
            <div className="tooling-coverage-page__body h-100">
                <ToolingCoverageGrid />
            </div>
        </div>
    );
};

export default ToolingCoveragePage;
