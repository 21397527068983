import { ActionsBar, ConfirmationModal } from 'Components/molecules';
import { Form } from 'react-bootstrap';
import { EngageModal } from '../EngageModal';
import {
    ACCEPT_FINDINGS_LABEL,
    ACCEPT_FINDINGS_VARIANT,
    CONFIRM,
    DEFAULT_ERROR_MSG,
    ENGAGE,
    ENGAGE_SUCCESS_MSG,
    FALSE_POSITIVE_LABEL,
    FALSE_POSITIVE_VARIANT,
} from 'Core-utils/constants/constants';
import EngageIcon from 'Assets/icons/chat.svg';
import EngageWithFlowIcon from 'Assets/icons/EngageWithFlowIcon.svg';
import AcceptFindingsIcon from 'Assets/icons/AcceptFindings.svg';
import FalsePositiveIcon from 'Assets/icons/FalsePositive.svg';
import { useI18n } from 'Hooks/useI18n';
import {
    CreateNotificationDto,
    DeliveryStrategyKind,
    FindingDto,
    FindingStatus,
    FindingsInsightsDto,
} from '@ampsec/platform-client';
import { convertFindingViewToNotificationData, getToastBody } from 'Core-utils/helpers/helpers';
import { ChangeEvent, useContext, useState } from 'react';
import { ToastsContext } from 'Rdx/contexts/ToastsContext';
import { useSelector } from 'react-redux';
import { RootState } from 'Rdx/store';
import { patchFindingsStatus, postEngageNotifications, triggerEngagement } from 'Apis/library';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

interface ActionsTrayProps {
    showActionsTray: boolean;
    CountOfSelectedItems: number;
    isFindingsGrid: boolean;
    selectedRows: FindingDto[] | FindingsInsightsDto[];
    matchingEngagements: string[];
    noOfUsers: number;
    onActionsTrayClose: () => void;
    onClear: () => void;
    onActionShow: () => void;
    onRefreshData: () => void;
    clearSelectedRows: () => void;
}

const ActionsTray = ({
    showActionsTray,
    CountOfSelectedItems,
    isFindingsGrid = true,
    selectedRows,
    matchingEngagements,
    noOfUsers,
    onActionsTrayClose,
    onClear,
    onActionShow,
    onRefreshData,
    clearSelectedRows,
}: ActionsTrayProps) => {
    const { t } = useI18n();
    const [show, setShow] = useState<boolean>(false);
    const [showEngageWithFlowModal, setShowEngageWithFlowModal] = useState<boolean>(false);
    const { addToast } = useContext(ToastsContext);
    const hasBanner = useSelector((state: RootState) => state.featureModel.enableBanner);
    const [copySecurityTeam, setCopySecurityTeam] = useState<boolean>(false);
    const [forceTrigger, setForceTrigger] = useState<boolean>(false);

    const handleSend = (text: string, strategy: DeliveryStrategyKind, includeFinding = false) => {
        let NotificationDataArray: CreateNotificationDto[];
        if (isFindingsGrid) {
            NotificationDataArray = selectedRows.map((selectableRow) =>
                convertFindingViewToNotificationData(
                    text,
                    copySecurityTeam,
                    strategy,
                    includeFinding,
                    undefined,
                    undefined,
                    selectableRow as FindingDto,
                ),
            );
        } else {
            NotificationDataArray = selectedRows.map((selectedRow) =>
                convertFindingViewToNotificationData(
                    text,
                    copySecurityTeam,
                    strategy,
                    includeFinding,
                    selectedRow as FindingsInsightsDto,
                ),
            );
        }

        postEngageNotifications(NotificationDataArray)
            .then((response) => {
                addToast({
                    severity: response?.error ? 'error' : 'success',
                    body: getToastBody(response?.error || ENGAGE_SUCCESS_MSG),
                });
            })
            .catch((error) => {
                addToast({
                    severity: 'error',
                    body: DEFAULT_ERROR_MSG + error.message,
                });
            });
        setShow(false);
        onActionShow();
        onClear();
        clearSelectedRows();
    };
    const handleEngageClick = () => setShow((show) => !show);

    const handleEngageWithFlowClick = () => setShowEngageWithFlowModal((show) => !show);
    const handleEngageWithFlowClose = () => setShowEngageWithFlowModal(false);
    const handleEngageWithFlowConfirm = () => {
        let fids: string[];
        if (isFindingsGrid) {
            fids = (selectedRows as FindingDto[]).map((row: FindingDto) => row.id);
        } else {
            fids = (selectedRows as FindingsInsightsDto[]).map((row: FindingsInsightsDto) => row.id);
        }

        triggerEngagement(fids, forceTrigger)
            .then((response) => {
                addToast({
                    severity: response?.success ? 'success' : 'error',
                    body: getToastBody(response?.message || ENGAGE_SUCCESS_MSG),
                });
            })
            .catch((error) => {
                addToast({
                    severity: 'error',
                    body: DEFAULT_ERROR_MSG + error.message,
                });
            });
        setShowEngageWithFlowModal(false);
        onActionShow();
        onClear();
        clearSelectedRows();
    };

    const handleClose = () => setShow(false);

    const handleAcceptFindings = () => {
        handleAction(FindingStatus.ACCEPTED_RISK, ACCEPT_FINDINGS_VARIANT);
    };

    const handleFalsePositive = () => {
        handleAction(FindingStatus.FALSE_POSITIVE, FALSE_POSITIVE_VARIANT);
    };

    const engageWithFlowClassName = classNames(
        'data-grid__svg-react-icon d-flex justify-content-center align-items-center',
    );

    const handleAction = (status: FindingStatus, variant: string) => {
        let selectedFindingsArray;
        if (isFindingsGrid) {
            selectedFindingsArray = (selectedRows as FindingDto[]).map((row: FindingDto) => ({ id: row.id, status }));
        } else {
            selectedFindingsArray = (selectedRows as FindingsInsightsDto[]).map((row: FindingsInsightsDto) => ({
                id: row.id,
                status,
            }));
        }

        patchFindingsStatus(selectedFindingsArray)
            .then((response) => {
                addToast({
                    severity: response?.error ? 'error' : 'success',
                    body: getToastBody(variant),
                });
                onRefreshData();
            })
            .catch((error) => {
                addToast({
                    severity: 'error',
                    body: DEFAULT_ERROR_MSG + error.message,
                });
            });

        onActionShow();
        onClear();
        clearSelectedRows();
    };

    const handleCopySecurityTeamCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCopySecurityTeam(event.target.checked);
    };

    const handleOverrideForceTriggerCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setForceTrigger(event.target.checked);
    };

    const actions = [
        {
            label: t('ENGAGE_WITH_FLOW'),
            icon: <ReactSVG src={EngageWithFlowIcon} className={engageWithFlowClassName} />,
            onClick: handleEngageWithFlowClick,
            disable: hasBanner,
        },
        {
            label: ENGAGE,
            icon: <ReactSVG src={EngageIcon} className={engageWithFlowClassName} />,
            onClick: handleEngageClick,
            disable: false,
        },
        {
            label: ACCEPT_FINDINGS_LABEL,
            icon: (
                <ReactSVG
                    src={AcceptFindingsIcon}
                    className="data-grid__svg-react-icon d-flex justify-content-center align-items-center"
                    data-testid="accept-findings-icon"
                />
            ),
            onClick: handleAcceptFindings,
            disable: false,
        },
        {
            label: FALSE_POSITIVE_LABEL,
            icon: (
                <ReactSVG
                    src={FalsePositiveIcon}
                    className="data-grid__svg-react-icon d-flex justify-content-center align-items-center"
                />
            ),
            onClick: handleFalsePositive,
            disable: false,
        },
    ];
    return (
        <div>
            <ActionsBar
                selectedFindings={CountOfSelectedItems}
                actions={actions}
                show={showActionsTray}
                onActionsClose={onActionsTrayClose}
            />
            {show && (
                <EngageModal
                    show={show}
                    onClose={handleClose}
                    onSend={handleSend}
                    copySecurityTeam={copySecurityTeam}
                    onChangeCheckbox={handleCopySecurityTeamCheckboxChange}
                />
            )}
            {showEngageWithFlowModal && (
                <ConfirmationModal
                    modalTitle={t('ENGAGE_WITH_FLOW')}
                    modalContent={
                        <div className="data-grid__flow-modal">
                            <Form.Check
                                inline
                                type="checkbox"
                                label={t('OVERRIDE_GHOST_MODE')}
                                className="mt-3 text-text-high-emphasis body5 mb-2"
                                checked={forceTrigger}
                                onChange={handleOverrideForceTriggerCheckboxChange}
                                data-testid="override-ghost-mode-checkbox"
                            />
                        </div>
                    }
                    onPrimaryButtonClick={handleEngageWithFlowConfirm}
                    primaryButtonLabel={CONFIRM}
                    show={showEngageWithFlowModal}
                    onClose={handleEngageWithFlowClose}
                    buttonVariant={'primary'}
                    matchingEngagements={matchingEngagements}
                    noOfUsers={noOfUsers}
                />
            )}
        </div>
    );
};

export default ActionsTray;
