import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { ToastMessage } from 'Components/molecules';
import { Typography } from 'Components/atoms';
import {
    CUSTOM_FINDINGS,
    ERROR_VARIANT,
    INTEGRATIONSTITLE,
    TOASTMESSAGE,
    WEBHOOK,
} from 'Core-utils/constants/constants';
import { IntegratedCard } from 'Components/molecules';
import { IconWithTypography } from 'Components/molecules';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { AddIntegrationModal, CredentialsScreenModal } from 'Components/organisms';
import { FIELDS_DATA } from './FieldsData';
import { ContentfulContext } from 'Rdx/contexts/contentfulContext';
import { ConnectorStatus, ConnectorUpsertDto } from '@ampsec/platform-client';
import { installConnector } from 'Apis/library';
import { InstalledVendorProps } from 'Core-utils/types/types';
import { useNavigate } from 'react-router-dom';
import PageErrorHandlerAndLoaderWrapper from 'Wrappers/PageErrorHandlerAndLoaderWrapper';
import { CUSTOM_FINDINGS_ROUTE, POLICIES_ROUTE } from 'Core-utils/routes';
import { ToastsContext } from 'Rdx/contexts/ToastsContext';
import AmplifierLogo from 'Assets/icons/amplifierLogoSymbol.svg';
import { useConnectorData } from 'Hooks/useConnectorsData';
import Integrations, { IntegrationDataProps } from 'Components/organisms/Integrations/Integrations';
import './styles.scss';

type IntegrationDataType = {
    id: string;
    imageUrl: string;
    domain: string;
};

interface VendorIntegrationProps {
    integratedData: IntegrationDataType[];
}

const VendorsPage = (integratedData: IntegrationDataType[]) => {
    const navigate = useNavigate();
    const [modals, setModals] = useState({
        modal1: false,
        modal2: false,
        modal3: false,
    });
    const [selectVendor, setSelectVendor] = useState<IntegrationDataProps>({} as IntegrationDataProps);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [integrationsData, setIntegrationsData] = useState<IntegrationDataProps[]>([] as IntegrationDataProps[]);
    const [variant, setVariant] = useState<'all' | 'selected'>('selected');
    const [isConfig, setIsConfig] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const { contentfulData } = useContext(ContentfulContext);
    const integrations = contentfulData?.integrations;
    const providerNameIntegrationMap = contentfulData?.providerNameIntegrationMap;
    const { addToast } = useContext(ToastsContext);
    const {
        extractedData,
        filteredIntegrations,
        reconstructedIntegrations,
        providers,
        providersList,
        domainsWithNoConnectors,
    } = useConnectorData();
    useEffect(() => {
        if (!filteredIntegrations) {
            return;
        }
        setIntegrationsData([
            ...Object.values<any>(reconstructedIntegrations)
                ?.map((integration) => ({
                    ...integration,

                    isIntegrated: false,
                    id: integration.providerKey,
                    vendor: integration.name,
                    imageUrl: `https:${integration.logo?.fields?.file?.url}`,
                    sideBar: [
                        {
                            title: 'Terms of use',
                            link: integration.termsOfUseLink,
                        },
                        {
                            title: 'Privacy statement',
                            link: integration?.privacyStatementLink,
                        },
                        {
                            title: 'Support',
                            link: integration.supportLink,
                        },
                    ],
                }))
                .sort((a, b) => a.vendor.localeCompare(b.vendor)),
        ] as IntegrationDataProps[]);
    }, [providersList, integrations]);

    const handleSearchBar = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    const handleCardClick = (id: string) => {
        const filteredItem = integrationsData.find((dataItem: IntegrationDataProps) => {
            return dataItem.id === id;
        });
        const selectVendorItem = {
            ...filteredItem,
            cid: extractedData.find((item) => item.title === filteredItem?.providerKey)?.cid,
        };

        setSelectVendor(selectVendorItem as IntegrationDataProps);
        const modalObj = {
            ...modals,
            ['modal1']: false,
            ['modal2']: true,
        };
        setIsConfig(false);
        setModals(modalObj);
    };
    const closeModal = (modalName: string) => () => {
        const modalObj = {
            ...modals,
            [modalName]: false,
        };
        setModals(modalObj);
        setQuery('');
    };
    const handleselectedcategoryTools = (selectedCategory: string) => {
        const modalObj = {
            ...modals,
            ['modal1']: true,
            ['modal2']: false,
        };
        setModals(modalObj);
        setSelectedCategory(selectedCategory);
        setVariant('selected');
    };
    const handleIntegration = () => {
        const modalObj = {
            ...modals,
            ['modal1']: false,
            ['modal2']: false,
            ['modal3']: true,
        };
        setModals(modalObj);
    };
    const handleCredentialsIntegration = () => {
        const modalObj = {
            ...modals,
            ['modal1']: false,
            ['modal2']: false,
            ['modal3']: false,
        };
        setModals(modalObj);
        setIntegrationsData((vendorState) => {
            const updatedselectVendor = {
                ...selectVendor,
                isIntegrated: true,
            };
            setSelectVendor(updatedselectVendor);
            const itemIndex = integrationsData.findIndex((data) => data.id === updatedselectVendor.id);

            if (itemIndex !== -1) {
                const updateddata = [...integrationsData];
                updateddata[itemIndex] = updatedselectVendor;
                setIntegrationsData(updateddata);
            }
            return [...vendorState, updatedselectVendor];
        });
    };

    const handleConfig = async (cid: string, vendor: string) => {
        if (cid) {
            navigate(`${POLICIES_ROUTE}/${cid}`, {
                state: {
                    cid: cid,
                },
            });
        } else {
            const selectedVendorItem = reconstructedIntegrations?.[vendor ?? ''];
            const provider = providers[selectedVendorItem?.providerKey as string];
            const connectorModel: ConnectorUpsertDto = {
                displayValue: selectedVendorItem?.providerKey as string,
                status: ConnectorStatus.DISABLED,
                pid: provider?.id,
                credentialStatus: selectedVendorItem?.credentialStatus,
            };
            try {
                const cid = await installConnector(connectorModel);
                navigate(`${POLICIES_ROUTE}/${cid}`, {
                    state: {
                        cid: cid,
                    },
                });
            } catch (error) {
                addToast({
                    body: 'Could not install connector',
                    severity: ERROR_VARIANT,
                });
            }
        }
    };

    const handleBackButtonAddIntegrationModal = () => {
        const modalObj = {
            ...modals,
            ['modal1']: true,
            ['modal2']: false,
            ['modal3']: false,
        };
        setModals(modalObj);
        setQuery('');
    };

    const handleBackButtonCredentialsModal = () => {
        const modalObj = {
            ...modals,
            ['modal1']: false,
            ['modal2']: true,
            ['modal3']: false,
        };
        setModals(modalObj);
    };

    const handleNewTool = () => {
        const modalObj = {
            ...modals,
            ['modal1']: true,
            ['modal2']: false,
            ['modal3']: false,
        };
        setModals(modalObj);
        setVariant('all');
        setSelectedCategory('ALL INTEGRATIONS');
    };

    const renderIntegratedCardWithConfig = (item: InstalledVendorProps) => {
        const handleHandleConfig = () => handleConfig(item.cid, item.title);
        return (
            <div key={`config-card-${item.cid}`} className="col-3 mb-3">
                <IntegratedCard
                    vendor={item.title}
                    imageUrl={item.imageUrl}
                    title={providerNameIntegrationMap?.[(item as any)?.provider?.displayValue]?.domains
                        .map((domain: { fields: { name: string } }) => domain.fields.name)
                        .join(' & ')}
                    handleCard={handleHandleConfig}
                    variant={item.status}
                />
            </div>
        );
        return null;
    };
    const renderIntegratedCardWithoutConfig = (card: IntegrationDataType, index: number) => {
        const handleCard = () => handleselectedcategoryTools(card.domain);
        return (
            <div key={`${index}-${card.id}-${card?.domain}-${card.domain}`} className="col-3 mb-3">
                <IntegratedCard
                    imageUrl={card.imageUrl}
                    title={card.domain}
                    handleCard={handleCard}
                    variant={ConnectorStatus.ACTIVE}
                />
            </div>
        );
    };

    const handleCustomFindingsClick = () => {
        navigate(CUSTOM_FINDINGS_ROUTE);
    };

    return (
        <>
            <div className="vendors-integration w-100" data-testid="vendors-integration">
                <div className="vendors-integration__title-container d-flex flex-row justify-content-between">
                    <Typography variant="body11" color="text-high-emphasis">
                        {INTEGRATIONSTITLE}
                    </Typography>
                    {!_.isEmpty(extractedData) && (
                        <div className="vendors-integration__column vendors-integration__button-container d-flex flex-row justify-content-end align-items-center">
                            <Button className="btn" onClick={handleNewTool}>
                                <IconWithTypography
                                    startIcon={<FaPlus className="vendors-integration__plus-icon" />}
                                    isRow={true}
                                    label="ADD NEW"
                                    labelColor="structural-selected"
                                    labelVariant="body2"
                                />
                            </Button>
                        </div>
                    )}
                </div>
                <div className="d-flex flex-column vendors-integration__toast-container" data-testid="vendorIntegrations">
                    {_.isEmpty(extractedData) && (
                        <ToastMessage toastText={TOASTMESSAGE[0]} toastSubText={TOASTMESSAGE[1]} />
                    )}
                    <div className="d-grid mt-3 gap-3">
                        <div className="row">
                            {extractedData?.map((connector) => renderIntegratedCardWithConfig(connector))}
                            {integratedData?.map((card) => {
                                return domainsWithNoConnectors?.map((category, index) => {
                                    if (card.domain === category) {
                                        return renderIntegratedCardWithoutConfig(card, index);
                                    }
                                });
                            })}
                            <div className="col-3 mb-3">
                                <IntegratedCard
                                    vendor={WEBHOOK}
                                    imageUrl={AmplifierLogo}
                                    title={CUSTOM_FINDINGS}
                                    handleCard={handleCustomFindingsClick}
                                    variant={'custom'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {modals.modal1 && (
                <Integrations
                    title={selectedCategory}
                    integrationsData={
                        variant === 'all'
                            ? integrationsData
                            : integrationsData.filter((data: IntegrationDataProps) =>
                                  data?.domains?.find((domain) => domain.fields.name === selectedCategory),
                              )
                    }
                    show={modals.modal1}
                    handleClose={closeModal('modal1')}
                    handleCardClick={handleCardClick}
                    variant={variant}
                    searchQuery={query}
                    onSearchQueryChange={handleSearchBar}
                />
            )}
            {modals.modal2 && (
                <AddIntegrationModal
                    imageUrl={selectVendor?.imageUrl}
                    providerName={selectVendor?.vendor ?? ''}
                    overviewTabContent={selectVendor?.overview}
                    show={modals.modal2}
                    sideBar={selectVendor?.sideBar}
                    vendor={selectVendor}
                    onBackClick={handleBackButtonAddIntegrationModal}
                    onClose={closeModal('modal2')}
                    disableBack={isConfig}
                    {...(selectVendor?.settingsFormSchema ? {} : { onIntegration: handleIntegration })}
                    onIntegration={() => handleConfig(selectVendor?.cid ?? '', selectVendor?.vendor)}
                />
            )}
            {!selectVendor?.settingsFormSchema && (
                <CredentialsScreenModal
                    data={FIELDS_DATA}
                    onClick={handleCredentialsIntegration}
                    show={modals.modal3}
                    onClose={closeModal('modal3')}
                    imageUrl={selectVendor?.imageUrl}
                    providerName={selectVendor?.vendor ?? ''}
                    onBackClick={handleBackButtonCredentialsModal}
                />
            )}
        </>
    );
};

const VendorsIntegration = ({ integratedData }: VendorIntegrationProps) => {
    const { isFetchingConnectors, connectorError, refreshConnectors } = useConnectorData();
    const renderVendorsPage = () => VendorsPage(integratedData);
    return (
        <PageErrorHandlerAndLoaderWrapper
            error={connectorError}
            render={renderVendorsPage}
            isLoading={isFetchingConnectors}
            handleRefresh={refreshConnectors}
        />
    );
};

export default VendorsIntegration;
