import _ from 'lodash';
import { CgArrowsExpandRight } from '@react-icons/all-files/cg/CgArrowsExpandRight';
import { useState } from 'react';
import {
    filterMap,
    MFA_COVERAGE_SUB_TITLE,
    MFA_COVERAGE_TITLE,
    MFA_TOTAL_USERS_LABEL,
    MFA_TOTAL_ACTIVE_LABEL,
} from 'Core-utils/constants/constants';
import { CustomCard } from 'Components/molecules';
import { IconWithTypography } from 'Components/molecules';
import { Typography } from 'Components/atoms';
import { MfaCoverage } from 'Components/molecules';
import { getMetricsReportsData } from 'Apis/library';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { BaseMetricResults } from 'Core-utils/types/types';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import mfaIcon from 'Assets/icons/mfaCoverage.svg';
import { FindingsGridModal } from 'Components/organisms';
import { updateURLWithFiltersAndSorting } from 'Core-utils/helpers/helpers';
import { REPORTS } from '@ampsec/platform-client';
import './styles.scss';

const COMPONENT_IDENTIFIER = 'Organization MFA Coverage';

const MfaCoverageCard = () => {
    const [show, setShow] = useState(false);
    const { data, error, isLoading, refresh } = useApiObjectData<BaseMetricResults, BaseMetricResults>(
        REPORTS.MFA_COVERAGE,
        getMetricsReportsData,
        (a) => a,
        { department: 'ALL' },
    );
    const value = data?.[MFA_TOTAL_USERS_LABEL]?.value;

    const MfaCoverageMetrics = () => {
        return (
            <div className="mfacoverage-card__mfacoverage-style">
                <MfaCoverage
                    total={data?.[MFA_TOTAL_USERS_LABEL]?.value}
                    unsafe={data?.[MFA_TOTAL_USERS_LABEL]?.value - data?.[MFA_TOTAL_ACTIVE_LABEL]?.value}
                />
            </div>
        );
    };
    const handleClose = () => {
        updateURLWithFiltersAndSorting({});
        setShow(false);
    };
    const handleClick = () => {
        setShow((show) => !show);
    };
    return (
        <div data-testid="mfa-card" className="mfacoverage-card h-100">
            <CustomCard
                cardType="oneThird"
                title={
                    <>
                        <IconWithTypography isRow={true} label={MFA_COVERAGE_TITLE} labelColor="primary" />
                        <CgArrowsExpandRight size={22} className="mfacoverage-card__icon" onClick={handleClick} />
                    </>
                }
                subTitle={
                    value === undefined ? null : (
                        <Typography variant="subtitle3" color="text-medium-emphasis">
                            {`${value} ${MFA_COVERAGE_SUB_TITLE}`}
                        </Typography>
                    )
                }
                content={
                    <div className="mfacoverage-card__error-container">
                        <ErrorHandlerAndLoaderWrapper
                            error={error}
                            isLoading={isLoading}
                            render={MfaCoverageMetrics}
                            handleRefresh={refresh}
                            isEmpty={_.isEmpty(data)}
                            ImageSrc={mfaIcon}
                            component={COMPONENT_IDENTIFIER}
                        />
                    </div>
                }
            />
            <FindingsGridModal
                title={MFA_COVERAGE_TITLE}
                onClose={handleClose}
                show={show}
                defaultFilter={filterMap['MFA']}
            />
        </div>
    );
};

export default MfaCoverageCard;
