import {
    ADD_NEW_ACTION,
    ALL_ACTIONS,
    AMPLIFIER,
    CREATE_ENGAGEMENT,
    DEFAULT_ERROR_MSG,
    DELETE_ENGAGEMENT,
    ENGAGEMENT_FLOW_TABS,
    ENGAGEMENT_MODAL_CONTENT,
    FINDINGS_TABLE_PAGINATION,
    YES_DELETE_IT,
} from 'Core-utils/constants/constants';
import { CustomTab } from 'Components/molecules';
import { Table } from 'Components/organisms';
import { ENGAGEMENT_FLOW_TABLE_HEADER } from './engagementFlowTable';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import {
    ACTIVE_FLOWS_ROUTE,
    DEFAULT_ROUTE,
    NOT_FOUND_ROUTE,
    ACTIVE_WORKFLOWS_ROUTE,
    ACTIONS_ROUTE,
} from 'Core-utils/routes';
import { NotFoundPage } from 'Components/pages';
import { Button } from 'react-bootstrap';
import { CreateEngagementModal } from 'Components/organisms/CreateEngagementModal';
import { useContext, useEffect, useState } from 'react';
import {
    addAction,
    deleteFlowById,
    fetchServiceProfiles,
    getAllActions,
    getGridData,
    updateEngagementFlow,
} from 'Apis/library';
import {
    AuthRole,
    CustomActionDto,
    CustomActionUpsertDto,
    FlowSpecDto,
    FlowSpecStatusKind,
    KIND,
    Page,
    ServiceProfile,
} from '@ampsec/platform-client';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import Actions from './Actions';
import { CustomActionsModal } from 'Components/organisms/CustomActionsModal';
import { CustomActionWizard } from 'Components/organisms/CustomActionWizard';
import { ToastsContext } from 'Rdx/contexts/ToastsContext';
import { RootState } from 'Rdx/store';
import { useSelector } from 'react-redux';
import { ConfirmationModal } from 'Components/molecules';
import PrivateRoute from 'Core-utils/PrivateRoute';
import './styles.scss';
import useTimeSpent from 'Hooks/useTimeSpent';

const COMPONENT_IDENTIFIER = 'Engagement Flow Grid';

const renderTable = (onDuplicate: (title: string, description: string) => void) => {
    useTimeSpent(COMPONENT_IDENTIFIER);
    const [show, setShow] = useState<boolean>(false);
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [idToDelete, setIdToDelete] = useState<string>('');
    const hasBanner = useSelector((state: RootState) => state.featureModel.enableBanner);
    const { addToast } = useContext(ToastsContext);

    const transformer = (dataItem: Page<FlowSpecDto>) => {
        return dataItem.data;
    };
    const { data } = useApiObjectData('flowSpecs', getGridData, transformer, {
        limit: 100,
    });
    const { data: apiData } = useApiObjectData('flowSpecs', getGridData, transformer);
    const handleMoreActions = (item: string, id: string) => {
        if (item === 'DELETE') {
            setShow(true);
            setIdToDelete(id);
        } else {
            const duplicateData = apiData.find((item) => item.id === id);
            onDuplicate(duplicateData?.name ?? '', duplicateData?.description ?? '');
        }
    };
    const handleUpdateStatus = (id: string, value: FlowSpecStatusKind) => {
        const getFlowById = data.find((item) => item.id === id);
        updateEngagementFlow({
            ...(getFlowById ?? ({} as FlowSpecDto)),
            status: value,
        })
            .then(() => setRefreshData((refreshData) => !refreshData))
            .catch(() =>
                addToast({
                    severity: 'error',
                    body: DEFAULT_ERROR_MSG,
                }),
            );
    };
    const handleDeleteClick = () => {
        deleteFlowById(idToDelete)
            .then(() => setRefreshData((refreshData) => !refreshData))
            .catch(() =>
                addToast({
                    severity: 'error',
                    body: DEFAULT_ERROR_MSG,
                }),
            );
        setShow(false);
    };
    const handleCloseModal = () => {
        setShow(false);
    };
    const tableBodyHeight = hasBanner ? '72vh' : '85vh';
    return (
        <>
            <Table
                operation="_summary"
                columns={ENGAGEMENT_FLOW_TABLE_HEADER(handleUpdateStatus, handleMoreActions)}
                rowsPerPageOptions={FINDINGS_TABLE_PAGINATION}
                showSubHeader={false}
                tableBodyHeight={tableBodyHeight}
                defaultSortId="name"
                cacheKey={'flowSpecs'}
                transformer={(a) => a}
                refreshTableData={refreshData}
                updateUrl={true}
            />
            <ConfirmationModal
                modalTitle={DELETE_ENGAGEMENT}
                modalContent={ENGAGEMENT_MODAL_CONTENT}
                onPrimaryButtonClick={handleDeleteClick}
                primaryButtonLabel={YES_DELETE_IT}
                show={show}
                onClose={handleCloseModal}
                buttonVariant="danger"
            />
        </>
    );
};

function EngagementFlowPage() {
    const [modals, setModals] = useState({
        showCreateEngagement: false,
        showCustomActions: {
            modal1: false,
            modal2: false,
        },
    });

    const auth = useSelector((state: RootState) => state.auth);
    const [variant, setVariant] = useState<'all' | 'selected'>('selected');
    const [query, setQuery] = useState<string>('');
    const [defaultTitle, setDefaultTitle] = useState<string>('');
    const [defaultDescription, setDefaultDescription] = useState<string>('');
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const { data: actionsData } = useApiObjectData<CustomActionDto[], CustomActionDto[]>(
        KIND.CUSTOM_ACTIONS,
        getAllActions,
        (a) => a,
        { isTemplate: true },
    );
    const [id, setId] = useState<string>('');
    const [serviceProfiles, setServiceProfiles] = useState<ServiceProfile[]>([]);

    useEffect(() => {
        fetchServiceProfiles().then((data: any) => setServiceProfiles(data));
    }, []);

    const location = useLocation();

    const buttonText = location.pathname === ACTIVE_WORKFLOWS_ROUTE ? CREATE_ENGAGEMENT : ADD_NEW_ACTION;

    const handleShow = () => {
        if (buttonText === CREATE_ENGAGEMENT) {
            setModals({ ...modals, showCreateEngagement: true });
        } else {
            const customActionsModal = { ...modals.showCustomActions, modal1: true, modal2: false };
            setModals({ ...modals, showCustomActions: customActionsModal });
            setVariant('all');
        }
    };

    const handleClose = () => {
        if (buttonText === CREATE_ENGAGEMENT) {
            setModals({ ...modals, showCreateEngagement: false });
        } else {
            const customActionsModal = { ...modals.showCustomActions, modal1: false, modal2: false };
            setModals({ ...modals, showCustomActions: customActionsModal });
            setId('');
        }
    };

    const handleSearchBar = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    const handleCardClick = async (id: string) => {
        const customActionsModal = { ...modals.showCustomActions, modal1: false, modal2: true };
        setModals({ ...modals, showCustomActions: customActionsModal });
        setId(id);
    };

    const handleSave = async (action: CustomActionUpsertDto) => {
        await addAction({
            isTemplate: false,
            kind: 'REST_ACTION',
            retryStrategy: {
                ...action.retryStrategy,
                kind: 'CONSTANT_BACKOFF',
                maxRetries: action.retryStrategy?.maxRetries || 0,
                delay: action.retryStrategy?.delay || 0,
            },
            meta: {
                ...action.meta,
                request: {
                    ...action.meta.request,
                    payload: action.meta.request.payload && {
                        ...action.meta.request.payload,
                        kind: 'TEMPLATE',
                    },
                },
            },
            description: action.description && action.description,
            displayValue: action.displayValue && action.displayValue,
        }).then(() => setRefreshData((refreshData) => !refreshData));
        const customActionsModal = { ...modals.showCustomActions, modal1: false, modal2: false };
        setModals({ ...modals, showCustomActions: customActionsModal });
        setId('');
    };

    const handleDuplicate = (title: string, description: string) => {
        setModals({ ...modals, showCreateEngagement: true });
        setDefaultTitle('Clone - ' + title);
        setDefaultDescription(description);
    };

    const allData: CustomActionDto[] = [
        {
            id: '',
            displayValue: AMPLIFIER,
            description: 'Amplifier',
            meta: {
                request: {
                    method: '',
                    url: '',
                    params: {},
                    payload: {
                        value: '',
                        kind: 'TEMPLATE',
                        format: 'JSON',
                    },
                    headers: { temp: 'temp' },
                },
            },
            isTemplate: false,
            kind: 'REST_ACTION',
            retryStrategy: {
                kind: 'CONSTANT_BACKOFF',
                maxRetries: 0,
                delay: 0,
            },
            createdAt: '',
            updatedAt: '',
            deletedAt: null,
        },
        ...(Array.isArray(actionsData) ? actionsData : []),
    ];

    return (
        <div className="d-flex flex-column engagement-flow__content h-100">
            <div className="engagement-flow__tab-cont d-flex justify-content-between">
                <CustomTab tabs={ENGAGEMENT_FLOW_TABS} />
                <Button
                    variant="primary-500"
                    className="engagement-flow__tab-cont__engagement_button body1 text-text-black"
                    onClick={handleShow}
                >
                    {buttonText}
                </Button>
            </div>
            <Routes>
                <Route path={DEFAULT_ROUTE} element={<Navigate to={ACTIVE_FLOWS_ROUTE} />} />
                <Route
                    path={ACTIVE_FLOWS_ROUTE}
                    element={
                        <PrivateRoute
                            element={renderTable(handleDuplicate)}
                            userRole={(auth.user.role as unknown) as AuthRole}
                            allowedRoles={[AuthRole.ADMIN, AuthRole.OWNER]}
                        />
                    }
                />
                {/* TODO:To be changed with Templates tab */}
                <Route
                    path={ACTIONS_ROUTE}
                    element={
                        <PrivateRoute
                            element={<Actions refresh={refreshData} serviceProfiles={serviceProfiles} />}
                            userRole={(auth.user.role as unknown) as AuthRole}
                            allowedRoles={[AuthRole.ADMIN, AuthRole.OWNER]}
                        />
                    }
                />

                <Route path={NOT_FOUND_ROUTE} element={<NotFoundPage />} />
            </Routes>
            <CreateEngagementModal
                show={modals.showCreateEngagement}
                onClose={handleClose}
                defaultTitle={defaultTitle}
                defaultDescription={defaultDescription}
            />
            <CustomActionsModal
                data={allData}
                show={modals.showCustomActions.modal1}
                onClose={handleClose}
                onCardClick={handleCardClick}
                variant={variant}
                query={query}
                onSearchQueryChange={handleSearchBar}
                title={ALL_ACTIONS}
                serviceProfileData={serviceProfiles}
            />
            <CustomActionWizard
                show={modals.showCustomActions.modal2}
                handleSave={handleSave}
                onClose={handleClose}
                id={id}
            />
        </div>
    );
}

export default EngagementFlowPage;
