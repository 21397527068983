import _ from 'lodash';
import { Table } from 'Components/organisms';
import { useParams } from 'react-router-dom';
import {
    Category,
    FindingDto,
    FindingKind,
    FindingSeverity,
    FindingStatus,
    FlowSpecDto,
    FlowSpecStatusKind,
    KIND,
    Page,
    ProviderDto,
} from '@ampsec/platform-client';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import {
    DATE_TEXT,
    DESCRIPTION,
    ENGAGEMENT,
    FINDINGS_TABLE_PAGINATION,
    FindingStatusLabelMap,
    HEADER_SCROLL_HEIGHT,
    ISSUE_TYPE_TEXT,
    SEVERITY_TEXT,
    STATUS,
    optionsFields,
} from 'Core-utils/constants/constants';
import { getGridData, getProvidersData } from 'Apis/library';
import { useContext, useMemo, useState } from 'react';
import { ContentfulContext } from 'Rdx/contexts/contentfulContext';
import { TableColumn } from 'react-data-table-component';
import { DateTimeFormatter, opacityClassName } from 'Core-utils/helpers/helpers';
import { ProgressBar } from 'Components/atoms';
import { Chip } from 'Components/atoms';
import { Typography } from 'Components/atoms';
import { FindingChip } from 'Components/molecules/FindingChip';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CustomTooltip } from 'Components/atoms';
import { useI18n } from 'Hooks/useI18n';
import './styles.scss';
import ActionsTray from '../ActionsTray/ActionsTray';

const COMPONENT_IDENTIFIER = 'Users Details Table';

export interface UserSpecificId {
    userSpecificId: string;
}

const FINDINGS_TABLE_HEADER = 'Findings';
const HeaderComponent = (
    <div className="h-10 mt-3 d-inline-block">
        <Typography variant="subtitle1" color="text-high-emphasis">
            {FINDINGS_TABLE_HEADER}
        </Typography>
    </div>
);

const UserFindingsTable = () => {
    const { id: userId } = useParams();
    const { contentfulData } = useContext(ContentfulContext);
    const integrations = contentfulData?.integrations;
    const providerMap = contentfulData?.providerNameIntegrationMap;
    const [selectedRows, setSelectedRows] = useState<FindingDto[]>([]);
    const [actionsShow, setActionsShow] = useState<boolean>(true);
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [clear, setClear] = useState<boolean>(false);
    const { t } = useI18n();
    const providerDisplayValueToLogoMap = integrations
        ? Object.keys(integrations)?.reduce((acc: { [k: string]: string }, integration: string) => {
              acc[
                  integrations?.[integration].providerKey
              ] = `https:${integrations?.[integration]?.logo?.fields?.file?.url}`;
              return acc;
          }, {})
        : {};

    const flowSpecTransformer = (dataItem: Page<FlowSpecDto>) => {
        return dataItem.data;
    };

    const { data: fetchFlowSpecs } = useApiObjectData('flowSpecs', getGridData, flowSpecTransformer, {
        limit: 100,
    });

    const matchingFlowSpecs = useMemo(() => {
        if (selectedRows.length > 0) {
            const eventTypes = selectedRows.map((row) => row.kind);
            return fetchFlowSpecs
                .filter((fs) => fs.status !== FlowSpecStatusKind.PAUSED)
                .filter((fs) =>
                    // eslint-disable-next-line max-nested-callbacks
                    fs.triggerFilter.trigger.some((t) => eventTypes.includes(t.eventType as FindingKind)),
                );
        }
        return [];
    }, [selectedRows]);

    const renderFindingTooltip = (row: FindingDto, key: keyof FindingDto, override?: string) => (props: any) => (
        <Tooltip className="w-40" id={`finding-tooltip-${row.id}`} {...props}>
            <Typography variant="body3">{`${override || (key && row?.[key]) || row?.[key]}`}</Typography>
        </Tooltip>
    );

    const FINDINGS_TABLE_COLUMNS: TableColumn<FindingDto>[] = [
        {
            id: 'severity',
            name: (
                <div className="w-100 text-center">
                    <Typography variant="body2">{SEVERITY_TEXT}</Typography>
                </div>
            ),
            center: true,
            sortable: true,
            width: '9.375rem',
            wrap: true,
            cell: (row) => {
                const variant = row.status === 'CLOSED' ? 'resolved_issue_variant' : 'severity';
                return (
                    <div className={`text-uppercase ${opacityClassName(row)}`}>
                        <Chip value={row.severity} variant={variant} />
                    </div>
                );
            },
        },
        {
            id: 'status',
            name: (
                <div>
                    <Typography variant="body2">{STATUS}</Typography>
                </div>
            ),
            wrap: true,
            cell: (row) => {
                return (
                    <div className={`text-uppercase ${opacityClassName(row)}`}>
                        <Typography variant={'subtitle3'}>
                            {FindingStatusLabelMap?.[row.status] || row.status}
                        </Typography>
                    </div>
                );
            },
            width: '7.5rem',
        },
        {
            id: 'category',
            name: (
                <div className="w-100 text-center">
                    <Typography variant="body2">{ISSUE_TYPE_TEXT}</Typography>
                </div>
            ),
            center: true,
            sortable: false,
            maxWidth: '9.375rem',
            cell: (row) => (
                <FindingChip
                    category={row.category}
                    className={opacityClassName(row)}
                    provider={row.provider?.displayValue ?? ''}
                />
            ),
            width: '11.25rem',
        },
        {
            id: 'provider.displayValue',
            name: (
                <div className="w-100 text-left">
                    <Typography variant="body2">Provider</Typography>
                </div>
            ),
            center: true,
            width: '10rem',
            cell: (row) => {
                return (
                    <div className="d-flex flex-row gap-1 w-100">
                        <img
                            alt={`${row.provider?.displayValue} logo`}
                            className="provider_logo"
                            src={
                                (providerDisplayValueToLogoMap?.[row.provider?.displayValue!] as string) ||
                                'https://via.placeholder.com/128'
                            }
                        />
                        <div className="d-flex flex-column justify-content-center">
                            <div className={opacityClassName(row)}>
                                {providerMap?.[row.provider?.displayValue || 'UNKNOWN']?.name ??
                                    `${row.provider?.displayValue}*` ??
                                    'UNKNOWN'}
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            id: 'finding',
            minWidth: '18.5rem',
            name: <Typography variant="body2">Finding</Typography>,
            width: '18.5rem',
            cell: (row) => (
                <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderFindingTooltip(
                        row,
                        'displayValue',
                        _.startCase(t(row.displayValue + '_description', row.displayValue ?? 'UNKNOWN')),
                    )}
                >
                    <div className={opacityClassName(row)}>
                        <Typography variant="subtitle3" style={{ whiteSpace: 'unset' }}>
                            {_.startCase(t(row.displayValue + '_description', row.displayValue ?? 'UNKNOWN'))}
                        </Typography>
                    </div>
                </OverlayTrigger>
            ),
        },
        {
            id: 'discoveredAt',
            name: <Typography variant="body2">{DATE_TEXT}</Typography>,
            sortable: true,
            width: '10rem',
            cell: (row) => (
                <div className={opacityClassName(row)}>
                    <Typography variant="body5">{DateTimeFormatter(row.discoveredAt ?? '')}</Typography>
                </div>
            ),
        },
        {
            id: 'numberOfEngagements',
            name: <Typography variant="body2">{ENGAGEMENT}</Typography>,
            sortable: false,
            minWidth: '15rem',
            maxWidth: '15rem',
            cell: (row) => {
                return (
                    <div
                        className={`findings-table__engagement-cell d-flex align-center justify-content-between ${opacityClassName(
                            row,
                        )}`}
                    >
                        <ProgressBar percentage={row.status !== FindingStatus.OPEN ? 100 : row.numberOfEngagements} />
                        <Typography variant="body5">
                            {row.status !== FindingStatus.OPEN ? 100 + '%' : `${row.numberOfEngagements}%`}
                        </Typography>
                    </div>
                );
            },
        },
        {
            id: 'description',
            name: <Typography variant="body2">{DESCRIPTION}</Typography>,
            cell: (row) => (
                <CustomTooltip
                    id={`description-${row.id}-${row.aid}`}
                    label={`${row.description ?? ''}`}
                    labelColor="primary"
                    labelVariant="subtitle3"
                    overlayPlacement="left"
                    overlayContainerClassName="d-flex overflow-hidden w-100"
                    tooltipClassName="color-white text-white"
                >
                    <div className={`${opacityClassName(row)} d-flex overflow-hidden text-overflow-elipsis`}>
                        <Typography variant="subtitle3">{row.description}</Typography>
                    </div>
                </CustomTooltip>
            ),
            maxWidth: '100%',
            style: {
                maxWidth: '24rem',
            },
        },
    ];

    const transformer = (dataItem: Page<FindingDto>) => {
        return {
            ...dataItem,
            data: dataItem.data.map((item) => ({ ...item, 'provider.displayValue': item.provider?.displayValue })),
        };
    };

    const { data: ProvidersList } = useApiObjectData<ProviderDto, ProviderDto>(
        KIND.PROVIDERS,
        () => getProvidersData({ limit: 100 }),
        (a) => a,
    );

    const providerFilterOptions = _.map(ProvidersList, 'displayValue');

    const findingsFilterMap = {
        Severity: Object.values(FindingSeverity),
        Provider: providerFilterOptions,
        'Issue Type': Object.values(Category),
    };

    const findingsDropdownOptions: {
        [key: string]: FindingSeverity[] | Category[];
    } = {};

    optionsFields.forEach((field) => {
        findingsDropdownOptions[field.toLowerCase()] =
            findingsFilterMap?.[field as keyof typeof findingsFilterMap] || [];
    });

    const handleSelectedRowsChange = (selected: {
        allSelected: boolean;
        selectedCount: number;
        selectedRows: FindingDto[];
    }) => {
        setSelectedRows(selected.selectedRows);
        if (selected.selectedRows.length > 0) {
            setActionsShow(true);
        }
    };

    const handleActionsClose = () => {
        setSelectedRows([]);
        setClear((clear) => !clear);
        setActionsShow(false);
    };

    const handleClear = () => {
        setClear((clear) => !clear);
    };

    const handleActionShow = () => {
        setActionsShow(false);
    };

    const handleRefreshData = () => {
        setRefreshData((refreshData) => !refreshData);
    };

    const clearSelectedRows = () => {
        setSelectedRows([]);
    };

    const noOfUsers = _.uniqBy(selectedRows, 'user_id')?.length;

    return (
        <div className="findings-table d-flex align-items-center justify-content-center w-100 h-100">
            <Table
                columns={FINDINGS_TABLE_COLUMNS}
                titleComponent={HeaderComponent}
                rowsPerPageOptions={FINDINGS_TABLE_PAGINATION}
                tableBodyHeight={HEADER_SCROLL_HEIGHT}
                cacheKey={'findings'}
                transformer={transformer}
                filter={{ uid: userId, include: ['provider'] }}
                showSubHeader={false}
                dropdownOptions={findingsDropdownOptions}
                componentIdentifier={COMPONENT_IDENTIFIER}
                onSelectedRowsChange={handleSelectedRowsChange}
                clearSelectedRows={clear}
                selectableRows={true}
                refreshTableData={refreshData}
            />
            {selectedRows.length > 0 && actionsShow && (
                <ActionsTray
                    showActionsTray={selectedRows.length > 0 && actionsShow}
                    CountOfSelectedItems={selectedRows.length}
                    isFindingsGrid={true}
                    selectedRows={selectedRows}
                    onActionsTrayClose={handleActionsClose}
                    onClear={handleClear}
                    onActionShow={handleActionShow}
                    onRefreshData={handleRefreshData}
                    clearSelectedRows={clearSelectedRows}
                    matchingEngagements={matchingFlowSpecs.map((fs) => fs.name)}
                    noOfUsers={noOfUsers}
                />
            )}
        </div>
    );
};

export default UserFindingsTable;
