import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { IconWithTypography } from 'Components/molecules';
import { CustomCard } from 'Components/molecules';
import { Typography } from 'Components/atoms';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import { ENGAGEMENTVALUE, ENGAGEMENT_VALUE_LABELS, EngagementValuesLabelMap } from 'Core-utils/constants/constants';
import { formatValue } from 'Core-utils/helpers/helpers';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { getMetricsReportsData } from 'Apis/library';
import { BaseMetricResults, BaseMetric } from 'Core-utils/types/types';
import _ from 'lodash';
import { CgArrowsExpandRight } from '@react-icons/all-files/cg/CgArrowsExpandRight';
import { EngagementDashboardModal } from 'Components/organisms';
import EngageIcon from 'Assets/icons/engagement.svg';
import './styles.scss';

const COMPONENT_IDENTIFIER = 'Organization Product Value';

const EngagementValue = ({ label, value }: BaseMetric) => (
    <div className="d-flex flex-column align-items-center engagement-card__vessel">
        <Typography variant="body1" color="text-medium-emphasis">
            {label}
        </Typography>
        <Typography variant="h3" color="text-high-emphasis">
            {value ? formatValue(label, value) : '-'}
        </Typography>
    </div>
);

const EngagementCard = () => {
    const [show, setShow] = useState<boolean>(false);
    const { data, error, isLoading, refresh } = useApiObjectData<BaseMetricResults, BaseMetricResults>(
        'engagement-value',
        getMetricsReportsData,
        (a) => {
            // hack for demos
            if (a.dollars_saved_label) {
                if (a.dollars_saved_label.value < 40000) {
                    a.dollars_saved_label.value += 40000;
                }
            }
            return a;
        },
        { organization: 'ALL', department: 'ALL' },
    );
    const handleClick = () => setShow(true);

    const handleClose = () => setShow(false);

    const renderEngagementData = (data: BaseMetricResults) => {
        return (
            <Row className="engagement-card__engagement-row d-flex justify-content-center">
                {ENGAGEMENT_VALUE_LABELS.map((label) => (
                    <Col key={label} xs={6} md={6} lg={6} data-testid="engagement-card-values">
                        <EngagementValue
                            label={label}
                            value={data[EngagementValuesLabelMap[label]]?.value ?? 0}
                            kind={'BASE'}
                        />
                    </Col>
                ))}
            </Row>
        );
    };

    return (
        <>
            <CustomCard
                cardType="oneThird"
                title={
                    <>
                        <IconWithTypography isRow label={ENGAGEMENTVALUE} labelColor="primary" />
                        <CgArrowsExpandRight size={22} className="engagement-card__icon" onClick={handleClick} />
                    </>
                }
                content={
                    <div className="engagement-card__error-container">
                        <ErrorHandlerAndLoaderWrapper
                            error={error}
                            isLoading={isLoading}
                            render={() => renderEngagementData(data)}
                            handleRefresh={refresh}
                            isEmpty={_.isEmpty(data)}
                            ImageSrc={EngageIcon}
                            component={COMPONENT_IDENTIFIER}
                        />
                    </div>
                }
            />
            <EngagementDashboardModal onClose={handleClose} show={show} />
        </>
    );
};

export default EngagementCard;
